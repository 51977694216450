export const OLD_ASCEND_PRODUCT_IDS = {
  BASIC: 'fc002183-deaa-48b5-994c-de613a095c14',
  PRO: '3c7e283f-dff4-4407-b678-00a28e4becb9',
  UNLIMITED: 'b8772926-1d84-4694-9c4b-3fa583b45403',
}

export const NEW_ASCEND_PRODUCT_IDS = {
  BASIC: '588aa405-d199-4509-afb4-a11621984be6',
  PRO: 'bcbc441f-47da-4735-b58f-e5386647bf5f',
  UNLIMITED: '1f3d81c2-2743-4402-96a9-66c71d7690b0',
}

export const BRAZIL_ASCEND_PRODUCT_IDS = {
  BASIC: '2aad0a35-c80a-4797-9815-8ae9453151ee',
  PRO: 'f1425e14-ee48-4adf-a2d6-2d226f1784dd',
  UNLIMITED: '3ff82981-e2a9-46d8-9b3c-ba2d5f5706f4',
};

export const ASCEND_PLAN = {
  FREE: 'free',
  BASIC: 'basic',
  PRO: 'pro',
  UNLIMITED: 'unlimited',
}

export const MAP_PRODUCT_ID_TO_ASCEND_PLAN = {
  [OLD_ASCEND_PRODUCT_IDS.BASIC]: ASCEND_PLAN.BASIC,
  [NEW_ASCEND_PRODUCT_IDS.BASIC]: ASCEND_PLAN.BASIC,
  [BRAZIL_ASCEND_PRODUCT_IDS.BASIC]: ASCEND_PLAN.BASIC,
  [OLD_ASCEND_PRODUCT_IDS.PRO]: ASCEND_PLAN.PRO,
  [NEW_ASCEND_PRODUCT_IDS.PRO]: ASCEND_PLAN.PRO,
  [BRAZIL_ASCEND_PRODUCT_IDS.PRO]: ASCEND_PLAN.PRO,
  [OLD_ASCEND_PRODUCT_IDS.UNLIMITED]: ASCEND_PLAN.UNLIMITED,
  [NEW_ASCEND_PRODUCT_IDS.UNLIMITED]: ASCEND_PLAN.UNLIMITED,
  [BRAZIL_ASCEND_PRODUCT_IDS.UNLIMITED]: ASCEND_PLAN.UNLIMITED,
}
