import { SuccessActionTypes } from "@wix/forms-common"

export type MediaManagerUploadObjectRequest = {
  type: string
  docId?: string
  name?: string
  status: string
}

export const LinkPanelTypes = {
  NONE: 'none',
  PAGE: 'PageLink',
  LIGHTBOX: 'PageLink',
  EXTERNAL_LINK: 'ExternalLink',
  DOCUMENT_LINK: 'DocumentLink',
  ANCHOR_LINK: 'AnchorLink',
  DYNAMIC_PAGE_LINK: 'DynamicPageLink'
}

export const LinkPanelTypesToActionTypes = {
  [LinkPanelTypes.PAGE]: SuccessActionTypes.LINK,
  [LinkPanelTypes.ANCHOR_LINK]: SuccessActionTypes.LINK,
  [LinkPanelTypes.DYNAMIC_PAGE_LINK]: SuccessActionTypes.LINK,
  [LinkPanelTypes.EXTERNAL_LINK]: SuccessActionTypes.EXTERNAL_LINK,
  [LinkPanelTypes.DOCUMENT_LINK]: SuccessActionTypes.DOWNLOAD_DOCUMENT,
}


export const UploadStatuses = {
  UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',
  UPLOAD_FAILED: 'UPLOAD_FAILED',
  IN_PROCESS: 'IN_PROCESS',
  NONE: 'NONE',
}

export const DEFAULT_UPLOAD_OBJECT: MediaManagerUploadObjectRequest = {
  type: LinkPanelTypes.DOCUMENT_LINK,
  status: UploadStatuses.NONE,
  name: '',
  docId: '',
}

export const DEFAULT_LINK_OBJECT = { type: LinkPanelTypes.PAGE, target: '_self' }
export const DEFAULT_EXTERNAL_LINK_OBJECT = { type: LinkPanelTypes.EXTERNAL_LINK, target: '_self' }

export const VISIBLE_LINK_PANEL_SECTIONS = {
  PageLink: true,
  AnchorLink: false,
  NoLink: true,
  PhoneLink: false,
  ExternalLink: true,
  EmailLink: false,
  DocumentLink: false,
  LoginToWixLink: false,
  EdgeAnchorLinks: false,
  PopupLink: true,
}

export const VISIBLE_LINK_PANEL_SECTIONS_ADI = {
  PageLink: true,
  AnchorLink: false,
  NoLink: true,
  PhoneLink: false,
  ExternalLink: true,
  EmailLink: false,
  DocumentLink: false,
  LoginToWixLink: false,
  EdgeAnchorLinks: false,
  PopupLink: false,
}
