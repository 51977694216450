export type WARNING_PANELS = 'dontShowContributorsWarning' | 'disableOptInCheckboxWarning'

export interface WarningPanelDefinition {
  title: string
  panelTitle: string
  helpId?: string
  hideLearnMore?: boolean
  hideUserPreferences?: boolean
  bullets: string[]
  ok: string
  cancel: string
  height: number
}

export const warningTypeToDefinition: { [key in WARNING_PANELS]: WarningPanelDefinition } = {
  dontShowContributorsWarning: {
    title: 'siteContributorWarning.title',
    panelTitle: 'siteContributorWarning.panelTitle',
    helpId: '2e748e6c-e672-484a-8ec2-7a206f1a753d',
    bullets: ['siteContributorWarning.content.bullet1', 'siteContributorWarning.content.bullet2'],
    ok: 'siteContributorWarning.approveButtonText',
    cancel: 'siteContributorWarning.cancelButtonText',
    height: 306,
  },
  disableOptInCheckboxWarning: {
    title: 'subscriberDoubleOptInWarning.title',
    panelTitle: 'subscriberDoubleOptInWarning.popupTitle',
    helpId: '256b916c-f019-4ee2-a3e4-9d1e2ff25ba8',
    hideLearnMore: true,
    hideUserPreferences: true,
    bullets: ['subscriberDoubleOptInWarning.subtitle'],
    ok: 'subscriberDoubleOptInWarning.ok',
    cancel: 'subscriberDoubleOptInWarning.cancel',
    height: 260,
  },
}
