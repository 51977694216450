import webBiLogger from '@wix/web-bi-logger'
import { BI } from '../constants/bi-common'
import { sanitizePII } from '@wix/bi-logger-sanitizer/dist/src/lib/sanitizers'
import { EditorType } from '@wix/platform-editor-sdk'
import { getAppVersion } from './utils'
import initSchemaLogger from '@wix/bi-logger-form-builder'

const sanitizeBiLogger = (fields = {}) => {
  const sanitizedFields = {}

  Object.keys(fields).forEach((fieldName) => {
    sanitizedFields[fieldName] = sanitizePII(fields[fieldName])
  })

  return sanitizedFields
}

export const initBiLogger = ({ endpoint = BI.ENDPOINT, src = BI.SRC, defaults = {} } = {}) => {
  const appVersion = getAppVersion()
  const factory = webBiLogger.factory({ endpoint }).updateDefaults({ src, appVersion, ...defaults })
  const biLogger = factory.logger()
  const instance = initSchemaLogger(factory)()

  return {
    log: (fields) => {
      const sanitizedFields = sanitizeBiLogger(fields)
      return biLogger.log(sanitizedFields)
    },
    instance,
  }
}

export const initBiLoggerForEditorApp = (msid, uuid, builderOrigin: EditorType) => {
  return initBiLogger({
    defaults: { msid, builderOrigin, uuid },
  })
}
