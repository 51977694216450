import i18next from 'i18next'
import * as i18nextXHRBackend from 'i18next-xhr-backend'
import abTranslate from 'ab-translate/dist/src/abTranslate-runtime'

const i18nextCore = (locale, baseUrl, origin, experiments, cb) => {
  i18next.use(i18nextXHRBackend).init(
    {
      lng: locale,
      fallbackLng: 'en',
      keySeparator: '$',
      interpolation: {
        escapeValue: false,
      },
      backend: {
        loadPath: `${baseUrl}assets/locale/${origin}/messages_{{lng}}.json`,
        crossDomain: true,
        parse: translations => {
          try {
            return abTranslate(experiments, JSON.parse(translations))
          } catch (e) {
            return translations
          }
        },
      },
    },
    (err, t) => cb(err, t, i18next),
  )
}

export interface I18NContainer {
  t: Function
  i18nInstance: any
}

export const i18n = ({
  locale,
  baseUrl = '',
  origin = 'builder',
  experiments = {},
}): Promise<I18NContainer> => {
  return new Promise((resolve, reject) => {
    i18nextCore(locale, baseUrl, origin, experiments, (err, t, i18nInstance) =>
      err ? reject(err) : resolve({ t, i18nInstance }),
    )
  })
}
