import _ from 'lodash'
import { getTranslationByPlugin } from '../../utils/utils'
import {
  ROLE_DOWNLOAD_MESSAGE,
  ROLE_FORM,
  ROLE_MESSAGE,
  ROLE_NEXT_BUTTON,
  ROLE_PREVIOUS_BUTTON,
  ROLE_SUBMIT_BUTTON,
  AUTOFILL_MEMBER_EMAIL_ROLE,
  COMPLEX_FIELDS_INNER_FIELDS,
  COMPLEX_PHONE_ROLES,
  FIELDS,
} from '../../constants/roles'
import translations from '../../utils/translations'
import RemoteApi from '../../panels/commons/remote-api'
import { EVENTS } from '../../constants/bi'
import { filterAsync, getPrimaryConnection, getValidCollectionId, isComplexController } from './utils'
import { undoable, withBi, absorbException, withFedops } from './decorators'
import { generateRuntimeCoreApi } from './api-generator'
import LayoutApi from './layout/api'
import StyleApi from './style-panel/api'
import AddFormApi from './add-form/api'
import CollectionsApi from './collections/api'
import SettingsApi, { SETTINGS_API_NAME } from './settings-panel/api'
import ManagePanelsApi from './manage-panels/api'
import FieldsApi from './fields/api'
import PanelsDataApi from './panels-data/api'
import PremiumApi from './premium/api'
import StepsApi from './steps/api'
import AppState from './app-state/app-state'
import { MissingField, InputTypes } from '../../constants/field-types'
import { allowCollectionSync, FieldProperties } from './preset/fields/field-types-data'
import { CRM_TAGS, CRM_TYPES } from '../../constants/crm-types-tags'
import Experiments from '@wix/wix-experiments'
import PluginsApi from './plugins/api'
import {
  convertPluginsToFormsPlugins,
  getPlugins,
  convertToV2SubmissionLimitStructure,
} from './plugins/utils'
import { REGISTRATION_FORM_CRUCIAL_FIELD_PRESET_VALUES } from './plugins/basicApis/registration-form'
import { TABS } from '../../panels/form-settings-panel/constants'
import {
  COMPONENT_TYPES,
  FormsFieldPreset,
  RegistrationFieldPreset,
  FieldNameType,
  FormPlugin,
  SuccessActionTypes,
  NOTIFICATION_EVENTS,
} from '@wix/forms-common'
import { FedopsLogger } from '@wix/fedops-logger'
import { OLD_CONTROLLER_TYPE } from './manifests/app-manifest'
import { EditorType, Origin } from '@wix/platform-editor-sdk'
import ContactSyncApi from './contact-sync/api'
import { fieldsStore } from './preset/fields/fields-store'
import { settingsClient, Scope } from '@wix/app-settings-client'
import ApiSessionStorage from './session-storage'
import { captureBreadcrumb, handleError } from '../forms-editor-app/monitoring'
import { PAYMENT_OPTIONS } from '../../panels/payment-wizard-panel/constants'
import { WIX_APP_ID } from '../../constants'
import RulesApi from './rules/api'
import ComplexPhoneApi from './complex-phone/api'
import { getPluginAppState } from './plugins/pluginAppStates'
import ComplexAddressApi from './complex-address/api'
import { PanelName } from '../../constants/panel-names'
import { GEO_CODE_MAP } from './preset/fields/complex-fields/complex-phone/constants'
import { PublishSiteRequest, PluginV2 } from '@wix/ambassador-wix-form-builder-web/types'

export default class CoreApi {
  private experiments: Experiments
  private ravenInstance: RavenInstance
  public remoteApi: RemoteApi
  private biLogger: BILogger
  private editorType: EditorType
  private fedopsLogger: FedopsLogger
  private siteId: string
  private apiSessionStorage: ApiSessionStorage

  public layout: LayoutApi
  public style: StyleApi
  public addForm: AddFormApi
  public collectionsApi: CollectionsApi
  public settings: Partial<SettingsApi>
  public managePanels: ManagePanelsApi
  public fields: FieldsApi
  public contactSync: ContactSyncApi
  public premium: PremiumApi
  public appState: AppState
  public plugins: PluginsApi
  public steps: StepsApi
  public panelsData: PanelsDataApi
  public rules: RulesApi
  public complexPhone: ComplexPhoneApi
  public complexAddress: ComplexAddressApi

  constructor(
    private boundEditorSDK: BoundEditorSDK,
    private editorSDK: EditorSDK,
    {
      apis: { collectionsApi, remoteApi, complexPhone },
      experiments,
      ravenInstance,
      biLogger,
      origin,
      appDefinitionId,
      fedopsLogger,
      siteId,
    }: {
      apis: { collectionsApi; remoteApi: RemoteApi; complexPhone?: ComplexPhoneApi }
      experiments: Experiments
      ravenInstance: RavenInstance
      biLogger: BILogger
      origin: Origin
      appDefinitionId: string
      fedopsLogger: FedopsLogger
      siteId: string
    },
  ) {
    this.fedopsLogger = fedopsLogger
    const helpers = { experiments, biLogger, ravenInstance, fedopsLogger }
    this.collectionsApi = new CollectionsApi(
      boundEditorSDK,
      collectionsApi,
      appDefinitionId,
      helpers,
    )
    this.remoteApi = remoteApi
    this.boundEditorSDK = boundEditorSDK
    this.editorSDK = editorSDK
    this.experiments = experiments
    this.ravenInstance = ravenInstance
    this.biLogger = biLogger
    this.editorType = _.get(origin, 'type')
    this.siteId = siteId

    this.layout = new LayoutApi(boundEditorSDK, this, helpers)
    this.style = new StyleApi(boundEditorSDK, this, helpers)
    this.addForm = new AddFormApi(boundEditorSDK, this, remoteApi, helpers)
    this[SETTINGS_API_NAME] = new SettingsApi(boundEditorSDK, this, remoteApi, helpers)
    this.managePanels = new ManagePanelsApi(boundEditorSDK, editorSDK, this, helpers)
    this.fields = new FieldsApi(boundEditorSDK, this, remoteApi, helpers)
    this.contactSync = new ContactSyncApi(boundEditorSDK, this, remoteApi, helpers)
    this.premium = new PremiumApi(boundEditorSDK, this, remoteApi, helpers)
    this.appState = new AppState(boundEditorSDK, this, remoteApi, helpers)
    this.plugins = new PluginsApi(this)
    this.steps = new StepsApi(boundEditorSDK, this, helpers)
    this.panelsData = new PanelsDataApi(boundEditorSDK, this, remoteApi, helpers)
    this.apiSessionStorage = new ApiSessionStorage()
    this.rules = new RulesApi(boundEditorSDK, this, helpers)
    this.complexPhone = complexPhone || new ComplexPhoneApi(boundEditorSDK, this, helpers)
    this.complexAddress = new ComplexAddressApi(boundEditorSDK, this, helpers)
  }

  public async getAll(controllerRef: ComponentRef) {
    const settings = await this._getSettings(controllerRef)
    return settings.getAll()
  }

  @undoable()
  public async set(controllerRef, key, value) {
    const settings = await this._getSettings(controllerRef)
    try {
      await settings.set(key, value)
      return true
    } catch (err) {
      handleError(err, { tags: { funcName: 'appSettings.set' } })
      return false
    }
  }

  public async get<T>(controllerRef, key): Promise<T> {
    const settings = await this._getSettings(controllerRef)
    return settings.get<Promise<T>>(key)
  }

  public async removeExternalId(controllerRef: ComponentRef) {
    const adapter = await this.boundEditorSDK.appSettings.createSdkAdapter({ controllerRef })
    try {
      await adapter.setExternalId(null)
      return true
    } catch (err) {
      return false
    }
  }

  private async _getSettings(controllerRef: ComponentRef) {
    const adapter = await this.boundEditorSDK.appSettings.createSdkAdapter({ controllerRef })
    const apiUrl = await this.boundEditorSDK.appSettings.getApiUrl()
    return settingsClient({
      adapter,
      apiUrl,
      scope: Scope.COMPONENT,
    })
  }

  public async fetchAppConfig({ formComponentRef }: { formComponentRef: ComponentRef }) {
    const appConfig: AppConfig = await this.formsExtendApi({
      formComponentRef,
      apiPath: 'appConfig',
      payload: { formComponentRef },
    })

    return _.merge({}, appConfig)
  }

  public async formsExtendApi({
    formComponentRef,
    apiPath,
    payload = null,
  }: {
    formComponentRef: ComponentRef
    apiPath: string
    payload?: any
  }) {
    const defaultApi = await this.plugins.callDefaultApi(apiPath, payload)

    try {
      const componentConnection = await this.getComponentConnection(formComponentRef)
      const plugins = getPlugins(componentConnection)
      const pluginsApi = this.plugins.withPlugins(plugins)

      if (pluginsApi.supportApi(apiPath)) {
        return pluginsApi.callApi(apiPath, payload)
      }

      return defaultApi
    } catch (err) {
      return defaultApi
    }
  }

  public getExperiments() {
    return Promise.resolve(this.experiments.all())
  }

  public getAscendUpgradeUrl(origin) {
    const sdk = this.boundEditorSDK as any
    return sdk.info.getAscendUpgradeUrl({ origin, slug: 'wix-forms' })
  }

  public interactionStarted(interactionName) {
    this.fedopsLogger.interactionStarted(interactionName)
  }

  public interactionEnded(interactionName) {
    this.fedopsLogger.interactionEnded(interactionName)
  }

  public isResponsive() {
    return this.editorType === 'RESPONSIVE'
  }

  public getBusinessManagerUrl() {
    return this.boundEditorSDK.info.getBusinessManagerUrl()
  }

  public isADI() {
    return this.editorType === 'ADI' || this.editorType === ('ADI_MA' as any)
  }

  public isClassicEditor() {
    return this.editorType === 'CLASSIC'
  }

  public originEditorType() {
    return this.editorType
  }

  public setEditorType(editorType) {
    this.editorType = editorType
  }

  public setBiLogger(biLogger) {
    this.biLogger = biLogger
  }

  public log(payload = {}) {
    this.biLogger.log(payload)
  }

  public async moveConnectionByRole({
    sourceComponentRef,
    destComponentRef,
    controllerRef,
    connection: { role, config, isPrimary },
  }: {
    sourceComponentRef: ComponentRef
    destComponentRef: ComponentRef
    controllerRef: ComponentRef
    connection: Partial<ComponentConnection>
  }) {
    await this.boundEditorSDK.controllers.disconnect({
      role,
      controllerRef,
      connectToRef: sourceComponentRef,
    })
    const connection = await this.connect(
      { connectionConfig: config, role },
      controllerRef,
      destComponentRef,
      isPrimary,
    )

    return connection
  }

  @undoable()
  public async setComponentConnectionAndSave(
    connectToRef: ComponentRef,
    connectionConfig,
    deepMerge = true,
  ) {
    return this.setComponentConnection(connectToRef, connectionConfig, deepMerge)
  }

  @undoable()
  public async connectComponentAndSetFormConfig({
    componentConnection: { config, role, controllerRef, isPrimary },
    componentRef,
    formComponentRef,
    formConfig,
  }: {
    componentConnection: ComponentConnection
    componentRef: ComponentRef
    formComponentRef: ComponentRef
    formConfig: Partial<ComponentConfig>
  }) {
    await this.setComponentConnection(formComponentRef, formConfig)

    return this.connect({ connectionConfig: config, role }, controllerRef, componentRef, isPrimary)
  }

  @undoable()
  public async disconnectComponentAndSetFormConfig({
    componentConnection: { role, controllerRef },
    componentRef,
    formComponentRef,
    formConfig,
  }: {
    componentConnection: Partial<ComponentConnection>
    componentRef: ComponentRef
    formComponentRef: ComponentRef
    formConfig: Partial<ComponentConfig>
  }) {
    await this.setComponentConnection(formComponentRef, formConfig)

    return this.boundEditorSDK.controllers.disconnect({
      connectToRef: componentRef,
      controllerRef,
      role,
    })
  }

  public async connect(
    { connectionConfig, role, subRole }: { connectionConfig: any; role: string; subRole?: string },
    controllerRef: ComponentRef,
    connectToRef: ComponentRef,
    isPrimary: boolean = true,
  ) {
    await this.boundEditorSDK.controllers.connect({
      connectToRef,
      controllerRef,
      role,
      connectionConfig,
      isPrimary,
      subRole,
    })
    return { role, connectionConfig, connectToRef, controllerRef }
  }

  public async addComponentAndConnect(
    { data, connectionConfig, role },
    controllerRef: ComponentRef,
    containerRef: ComponentRef,
  ) {
    const connectToRef = await this.boundEditorSDK.components.add({
      componentDefinition: data,
      pageRef: containerRef,
    })

    return this.connect({ connectionConfig, role }, controllerRef, connectToRef)
  }

  public async filterAppManifestV2(manifest) {
    const controllers = await this.boundEditorSDK.controllers.listAllControllers()

    const controllersData = await Promise.all(
      _.map(controllers, ({ controllerRef }) =>
        this.boundEditorSDK.controllers.getData({ controllerRef }),
      ),
    )

    const componentsType = await Promise.all(
      _.map(controllers, ({ controllerRef }) =>
        this.boundEditorSDK.components.getType({ componentRef: controllerRef }),
      ),
    )

    const hasOldControllerType = _.some(controllersData, (v) => v.type === OLD_CONTROLLER_TYPE)
    const hasNoneDuplicableComponentType = _.some(
      componentsType,
      (v) => v !== 'platform.components.AppWidget',
    )

    const filteredControllersManifest = _.chain(manifest.controllersStageData)
      .mapValues((controllerTypeManifest, controllerType) => {
        if (controllerType === OLD_CONTROLLER_TYPE && !hasOldControllerType) {
          return null
        }

        return _.chain(controllerTypeManifest)
          .mapValues((controllerStateManifest, controllerState) => {
            if (controllerState === 'default' || hasNoneDuplicableComponentType) {
              return controllerStateManifest
            }

            return _.startsWith(controllerState, 'duplicatable') ? controllerStateManifest : null
          })
          .pickBy(_.identity)
          .value()
      })
      .pickBy(_.identity)
      .value()

    return {
      controllersStageData: filteredControllersManifest,
      exports: manifest.exports,
      appDescriptor: manifest.appDescriptor,
    }
  }
  // TODO: remove
  public async filterAppManifest(manifest) {
    const controllers = await this.boundEditorSDK.controllers.listAllControllers()

    const controllersData = await Promise.all(
      _.map(controllers, ({ controllerRef }) =>
        this.boundEditorSDK.controllers.getData({ controllerRef }),
      ),
    )

    const controllerTypes = _.map(controllersData, (controllerData) => controllerData.type)
    const filteredControllersData = _.pick(manifest.controllersStageData, controllerTypes)

    return {
      controllersStageData: { ...filteredControllersData },
    }
  }

  public async findConnectedComponent(
    controllerRef: ComponentRef,
    childRole,
  ): Promise<ComponentRef> {
    const childComps = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })
    const obj = await Promise.all(
      childComps.map(async (child) => {
        const { role } = await this.getComponentConnection(child)
        return role === childRole ? child : null
      }),
    )
    return <ComponentRef | null>_.find(obj, (x) => !!x)
  }

  public async findChildComponentsByRole(
    ancestorComponentRef: ComponentRef,
    roleToFilter: string | string[],
  ): Promise<ComponentRef[]> {
    const rolesToFilter = _.castArray(roleToFilter)
    const { controllerRef } = await this.getComponentConnection(ancestorComponentRef)
    const filteredByRoleComponents = await this.findConnectedComponentsByRole(
      controllerRef,
      rolesToFilter,
    )
    const childrenWithRole = await Promise.all(
      filteredByRoleComponents.map(async (comp) => {
        const ancestors = await this.boundEditorSDK.components.getAncestors({ componentRef: comp })
        return _.find(ancestors, (ancestor) => _.isEqual(ancestor, ancestorComponentRef)) && comp
      }),
    )
    return childrenWithRole.filter((child) => child)
  }

  private async _findConnectedComponentBy(
    controllerRef: ComponentRef,
    callback: (componentConnection: ComponentConnection) => boolean,
  ): Promise<ComponentRef[]> {
    const childComps = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })

    const obj = await Promise.all(
      childComps.map(async (child) => {
        const componentConnection = await this.getComponentConnection(child)
        return callback(componentConnection) ? child : null
      }),
    )

    return <ComponentRef[]>_.filter(obj, (x) => !!x)
  }

  public findConnectedComponentsByFieldType(
    controllerRef: ComponentRef,
    componentFieldType: FieldPreset,
  ): Promise<ComponentRef[]> {
    return this._findConnectedComponentBy(
      controllerRef,
      (connection) => _.get(connection, 'config.fieldType') === componentFieldType,
    )
  }

  public async findConnectedComponentsByCrmType(
    controllerRef: ComponentRef,
    crmType: string,
  ): Promise<ComponentRef[]> {
    return this._findConnectedComponentBy(
      controllerRef,
      (connection) => _.get(connection, 'config.crmType') === crmType,
    )
  }

  public async findConnectedComponentsByRole(
    controllerRef: ComponentRef,
    roleToFilter: string | string[],
  ): Promise<ComponentRef[]> {
    const rolesToFilter = _.castArray(roleToFilter)
    const childComps = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })
    return this._filterComponentsByRole(childComps, rolesToFilter)
  }

  public async findConnectedComponentsByPlugin(
    controllerRef: ComponentRef,
    plugin: FormPlugin,
  ): Promise<ComponentRef[]> {
    const childComps = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })
    return this._filterComponentsByRole(childComps, ROLE_FORM, {
      config: { plugins: [{ id: plugin }] },
    })
  }

  private async _filterComponentsByRole(
    components: ComponentRef[],
    roleToFilter: string | string[],
    dataToFilter = {},
  ) {
    const rolesToFilter = _.castArray(roleToFilter)
    const childrenData = await this.boundEditorSDK.components.get({
      componentRefs: components,
      properties: ['connections'],
    })

    return _.chain(childrenData)
      .filter((child) =>
        _.find(
          child.connections,
          (connection) =>
            connection.isPrimary &&
            _.includes(rolesToFilter, connection.role) &&
            _.isMatch(connection, dataToFilter),
        ),
      )
      .map((compData) => compData.componentRef)
      .value()
  }

  public async findComponentByRole(componentRef: ComponentRef, childRole) {
    const { controllerRef } = await this.getComponentConnection(componentRef)
    return this.findConnectedComponent(controllerRef, childRole)
  }

  public async getFormContainerOfAppWidget(controllerRef: ComponentRef) {
    return (await this.boundEditorSDK.components.getChildren({ componentRef: controllerRef }))[0]
  }

  public async getControllerType(controllerRef: ComponentRef) {
    return ((await this.boundEditorSDK.components.data.get({ componentRef: controllerRef })) as any || {})
      .controllerType
  }

  public async getFormContainerFromControllerOrAppWidget(
    componentRef: ComponentRef,
  ): Promise<ComponentRef> {
    const componentType = await this.boundEditorSDK.components.getType({ componentRef })
    if (componentType === 'platform.components.AppWidget') {
      return this.getFormContainerOfAppWidget(componentRef)
    } else if (componentType === 'platform.components.AppController') {
      return this.findConnectedComponent(componentRef, ROLE_FORM)
    } else if (componentType === COMPONENT_TYPES.FORM_CONTAINER) {
      return Promise.resolve(componentRef)
    }
    const errorMessage = 'Component is of unexpected type'
    this.ravenInstance.captureException(errorMessage, {
      stacktrace: true,
      extra: { componentType },
    })
    throw errorMessage
  }

  public generateRuntimeApi() {
    return generateRuntimeCoreApi(this, {
      layout: this.layout,
      style: this.style,
      settings: this.settings,
      addForm: this.addForm,
      managePanels: this.managePanels,
      fields: this.fields,
      contactSync: this.contactSync,
      premium: this.premium,
      appState: this.appState,
      steps: this.steps,
      panelsData: this.panelsData,
      rules: this.rules,
      complexPhone: this.complexPhone,
      complexAddress: this.complexAddress,
    })
  }

  public async getComponentConnection(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection,
  ): Promise<ComponentConnection> {
    if (componentConnection) {
      return componentConnection
    }

    const connections = await this.boundEditorSDK.controllers.listConnections({
      componentRef,
    })

    return getPrimaryConnection(connections)
  }

  public async getFormControllerRef(componentRef: ComponentRef): Promise<ComponentRef> {
    const { controllerRef } = await this.getComponentConnection(componentRef)
    const controllerType = await this.getControllerType(controllerRef)
    if (isComplexController(controllerType)) {
      const { controllerRef: formController } = await this.getComponentConnection(controllerRef)
      return formController
    }
    return controllerRef
  }

  public async getPlugins(componentRef) {
    const componentConnection = await this.getComponentConnection(componentRef)
    return convertPluginsToFormsPlugins(getPlugins(componentConnection))
  }

  public async getFormPlugins(componentRef) {
    const formCompRef: ComponentRef = await this.findComponentByRole(componentRef, ROLE_FORM)
    const { config } = await this.getComponentConnection(formCompRef)
    return convertPluginsToFormsPlugins(config.plugins)
  }

  public async setComponentConnectionsByConnectionRole(
    connectToRef: ComponentRef,
    connectionConfig,
    role: string,
    deepMerge: boolean = true,
  ) {
    const allConnections = await this.boundEditorSDK.controllers.listConnections({
      componentRef: connectToRef,
    })
    const connections = _.filter(allConnections, (connection) => connection.role === role)
    const connectPromises = _.map(connections, (connection) => {
      const mergedConfig = (deepMerge ? _.merge : _.assign)({}, connection.config, connectionConfig)

      return this.boundEditorSDK.controllers.connect({
        connectToRef,
        controllerRef: connection.controllerRef,
        role,
        connectionConfig: mergedConfig,
        isPrimary: _.get(connection, 'isPrimary', false),
      })
    })

    return Promise.all(connectPromises)
  }

  public async setComponentConnection(
    connectToRef: ComponentRef,
    connectionConfig,
    deepMerge = true,
  ) {
    const { controllerRef, role, config, subRole } = await this.getComponentConnection(connectToRef)
    const mergedConfig = (deepMerge ? _.merge : _.assign)({}, config, connectionConfig)

    return this.boundEditorSDK.controllers.connect({
      connectToRef,
      controllerRef,
      role,
      connectionConfig: mergedConfig,
      isPrimary: true,
      subRole,
    })
  }

  public async setComponentSubRole(
    connectToRef: ComponentRef,
    subRole: string,
    componentConnection?: ComponentConnection,
  ) {
    const { controllerRef, role, config: connectionConfig, isPrimary } =
      componentConnection || (await this.getComponentConnection(connectToRef))
    return this.boundEditorSDK.controllers.connect({
      connectToRef,
      controllerRef,
      role,
      connectionConfig,
      isPrimary,
      subRole,
    })
  }

  @absorbException('core-api')
  public async saveSite() {
    return this.boundEditorSDK.editor.save()
  }

  public async saveSiteIfUnsaved() {
    const isSiteSaved = await this.boundEditorSDK.info.isSiteSaved()
    return isSiteSaved || this.saveSite()
  }

  public async createAutoCollection(componentRef: ComponentRef, saveSite = true): Promise<string> {
    return this._createCollection(componentRef, { request_type: 'auto' }, saveSite)
  }

  @undoable()
  public async createManualCollection(
    componentRef: ComponentRef,
    extraBiData = {},
  ): Promise<string> {
    return this._createCollection(componentRef, extraBiData)
  }

  private async _createCollection(
    componentRef: ComponentRef,
    extraBiData = {},
    saveSite = true,
  ): Promise<string> {
    const primaryConnection = await this.getComponentConnection(componentRef)
    if (!_.get(primaryConnection, 'config')) {
      return
    }
    const {
      preset,
      plugins,
      collectionId: existingCollectionId,
      formName,
    } = primaryConnection.config

    const existingValidCollectionId = await this.getValidCollectionId(
      componentRef,
      existingCollectionId,
    )
    if (await this.collectionsApi.isCollectionExists(existingValidCollectionId)) {
      return existingValidCollectionId
    }

    const formId = await this.getFormId(componentRef, primaryConnection)

    const biData = {
      template: preset,
      form_comp_id: formId,
      ...extraBiData,
    }

    const fields = await this.fields.getFieldsSortByXY(componentRef)
    const collectionFields = _.flatten(
      fields.map((field) =>
        field.role === FIELDS.ROLE_FIELD_COMPLEX_ADDRESS_WIDGET ? field.childFields : field,
      ),
    )

    const fieldKeyCallback = (fieldComponent, fieldKey) =>
      this.setComponentConnection(fieldComponent, { collectionFieldKey: fieldKey })
    const collectionId = await this.collectionsApi.createCollection(
      { preset, fields: collectionFields, plugins, fieldKeyCallback, formName },
      { startBi: biData, endBi: biData },
    )

    await this.setComponentConnection(componentRef, {
      collectionId: `${formId}_${collectionId}`,
    })

    if (saveSite) {
      await this.editDraft(componentRef, collectionId)

      await this.saveSite()
    }

    return collectionId
  }

  public async handleComponentAddedToApp(controllerRef: ComponentRef, compRef: ComponentRef) {
    const controllerType = await this.getControllerType(controllerRef)
    if (controllerType === 'complexPhoneController') {
      await this.complexPhone.handleComponentAddedToComplexPhone(controllerRef, compRef)
    }

    return this._handleComponentAddedToForm(controllerRef, compRef)
  }

  private async _handleComponentAddedToForm(controllerRef: ComponentRef, compRef: ComponentRef) {
    const type = await this.boundEditorSDK.components.getType({ componentRef: compRef })
    if (!_.includes(InputTypes, type)) {
      return
    }
    try {
      const formRef: ComponentRef = await this.getFormContainerFromControllerOrAppWidget(
        controllerRef,
      )
      const componentConnection = await this.getComponentConnection(compRef)
      const compControllerRefId = _.get(componentConnection, 'controllerRef.id')

      if (compControllerRefId && compControllerRefId === controllerRef.id) {
        return
      }

      if (!formRef) {
        return
      }

      const formComponentConnection = await this.getComponentConnection(formRef)
      const {
        config: { plugins },
      } = formComponentConnection

      this.popNotificationAction({
        componentRef: formRef,
        plugins,
        notificationTrigger: NOTIFICATION_EVENTS.COMPONENT_DRAGGED_INTO_FROM,
      })
    } catch (e) {}
  }

  @withFedops('handle-duplicated-page')
  public async handleDuplicatedPage({ pageRef, originalPageRef }) {
    const isFormController = async (controllerRef: ComponentRef) => {
      const controllerType = await this.getControllerType(controllerRef)
      return !isComplexController(controllerType)
    }

    const duplicatedControllers = await this.boundEditorSDK.controllers.listControllers({
      pageRef,
    })
    const duplicatedFormControllers = await filterAsync(duplicatedControllers, (async (controller: any) => {
      return await isFormController(controller.controllerRef)
    }))

    const originalControllers = await this.boundEditorSDK.controllers.listControllers({
      pageRef: originalPageRef,
    })
    const originalFormControllers = await filterAsync(originalControllers, (async (controller: any) => {
      return await isFormController(controller.controllerRef)
    }))

    captureBreadcrumb({
      message: 'handleDuplicatedPage',
      category: 'core-api',
      data: { originalFormControllers, duplicatedFormControllers },
    })

    const controllers: { controllerRef: ComponentRef }[][] = _.zip(
      duplicatedFormControllers,
      originalFormControllers,
    )

    return Promise.all(
      _.map(controllers, ([{ controllerRef }, { controllerRef: originalControllerRef }]) =>
        this.addForm.handleDuplicatedForm({ controllerRef, originalControllerRef }),
      ),
    )
  }

  public async editDraft(componentRef, collectionId = null) {
    const componentConnection = await this.getComponentConnection(componentRef)
    if (!_.get(componentConnection, 'config')) {
      return
    }
    const { formName } = componentConnection.config
    const connectedFields = await this.fields.getFieldsSortByXY(componentRef)

    let hasCaptchaField = false

    _.forEach(connectedFields, (field) => {
      if (field.fieldType === FormsFieldPreset.GENERAL_RECAPTCHA) {
        hasCaptchaField = true
      }
    })

    const pluginsV2Dto = await this._preparePluginsV2({
      hasCaptchaField,
      componentConnection,
      componentRef,
    })
    const formId = await this.getFormId(componentRef, componentConnection)
    const formData: any = {
      formId,
      formName,
      fields: _.map(connectedFields, (connectedField) => ({
        fieldId: connectedField.componentRef.id,
        fieldName: connectedField.crmLabel,
      })),
      pluginsV2: pluginsV2Dto,
    }

    if (collectionId) {
      formData.collectionId = collectionId
    }

    return this.remoteApi.editDraft(formData)
  }

  public removeComponentRef(componentRef: ComponentRef) {
    return componentRef
      ? this.boundEditorSDK.components.remove({ componentRef })
      : Promise.resolve()
  }

  public async getButtonLabel(componentRef: ComponentRef): Promise<string> {
    const { label } = <any>await this.boundEditorSDK.components.data.get({ componentRef })
    return label
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS[PanelName.SUBMIT_SETTINGS].UPDATE_SUBMIT_VALUE })
  public updateButtonLabel(componentRef: ComponentRef, buttonLabel, _biData = {}) {
    return this.boundEditorSDK.components.data.update({
      componentRef,
      data: { label: buttonLabel },
    })
  }

  public async isTemplate(): Promise<boolean> {
    return !(await this.boundEditorSDK.info.isSiteSaved())
  }

  public getMetaSiteId(): Promise<string> {
    return this.boundEditorSDK.info.getMetaSiteId()
  }

  public getUserId(): Promise<string> {
    return this.boundEditorSDK.info.getUserId()
  }

  public getAppDefinitionId(): Promise<string> {
    return this.boundEditorSDK.document.info.getAppDefinitionId()
  }

  public getEditorSessionId(): Promise<string> {
    return this.boundEditorSDK.info.getEditorSessionId()
  }

  public async isMobileMode(): Promise<boolean> {
    return (await this.boundEditorSDK.info.getEditorMode()) === 'mobile'
  }

  private async _isComponentExists(componentRef: ComponentRef): Promise<boolean> {
    try {
      const componentLayout = await this.boundEditorSDK.components.layout.get({
        componentRef,
      })
      return !!componentLayout
    } catch (ex) {
      return false
    }
  }

  private async _isSubscribeFieldExistsWithoutEmailField(
    controllerRef: ComponentRef,
    emailFields: ComponentRef[],
  ) {
    const subscribeFields = await this.findConnectedComponentsByFieldType(
      controllerRef,
      FormsFieldPreset.GENERAL_SUBSCRIBE,
    )

    const isSubscribeFieldExistsWithoutEmailField =
      subscribeFields.length > 0 && emailFields.length == 0

    return isSubscribeFieldExistsWithoutEmailField
  }

  private async _isEmailFieldMissing(controllerRef: ComponentRef, emailFields: ComponentRef[]) {
    const formRef = await this.findConnectedComponent(controllerRef, ROLE_FORM)
    const { config } = await this.getComponentConnection(formRef)
    const hasGetSubscribersPlugin = _.chain(config)
      .get('plugins')
      .find({ id: FormPlugin.GET_SUBSCRIBERS })
      .value()

    if (!hasGetSubscribersPlugin) {
      return false
    }

    return emailFields.length === 0
  }

  public async isEmailFieldMissing(controllerRef): Promise<MissingField> {
    const emailFields = await this.findConnectedComponentsByCrmType(controllerRef, CRM_TYPES.EMAIL)

    const emailFieldMissing =
      (await this._isSubscribeFieldExistsWithoutEmailField(controllerRef, emailFields)) ||
      (await this._isEmailFieldMissing(controllerRef, emailFields))

    return emailFieldMissing
      ? { type: FieldNameType.PRESET, name: FormsFieldPreset.EMAIL, tab: TABS.SUBMIT_MESSAGE }
      : null
  }

  public async isFieldMissingByRole(
    componentRef,
    role,
    tab = TABS.SUBMIT_MESSAGE,
  ): Promise<MissingField> {
    const ref = await this.findComponentByRole(componentRef, role)
    return ref ? null : { type: FieldNameType.ROLE, name: role, tab }
  }

  public async isMissingFieldByPreset(
    controllerRef: ComponentRef,
    fieldPreset: FieldPreset,
  ): Promise<MissingField> {
    const fields = await this.findConnectedComponentsByFieldType(controllerRef, fieldPreset)

    return fields.length === 0 ? { type: FieldNameType.PRESET, name: fieldPreset } : null
  }

  public async isAppWidget(componentRef) {
    return (
      (await this.boundEditorSDK.components.getType({ componentRef })) ===
      'platform.components.AppWidget'
    )
  }

  public async isMediaContainer(componentRef) {
    return (
      (await this.boundEditorSDK.components.getType({ componentRef })) ===
      'wysiwyg.viewer.components.MediaContainer'
    )
  }

  /*
   TEMP SOLUTION: When we delete form we will get in listConnectedComponents (few lines below) all components
   including other forms in other pages when user duplicated the page - we want to skip the implementation below
   when we got here with duplicated form the user didn't deleted manually
   TODO: Wrap the code below in if-statement when we will have the AppWidget
   `
   const connectedViaAppWidget = await this.boundEditorSDK.components.getType({ componentRef }) === '...AppWidget'
   if (!connectedViaAppWidget) {
   // Do the code below for backwards compatibility
   }
   `
   */
  private async _handleFormDeletion({
    componentRef,
    componentConnection,
  }: {
    componentRef: ComponentRef
    componentConnection: ComponentConnection
  }) {
    const { controllerRef, config } = componentConnection
    if (_.get(config, 'isDummyForm')) {
      return
    }
    const esi = await this.getEditorSessionId()
    this.biLogger.log({
      evid: EVENTS.EDITOR.DELETE_FORM,
      esi: esi,
      form_comp_id: await this.getFormId(componentRef, componentConnection),
      template: config.preset,
    })

    const duplicatedForm = await this.findConnectedComponent(controllerRef, ROLE_FORM)
    if (duplicatedForm) {
      return
    }

    if (await this.isMobileMode()) {
      return
    }

    const connectedRefs = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })

    await Promise.all(
      connectedRefs.map(async (connectedComponentRef) =>
        this.boundEditorSDK.components.remove({ componentRef: connectedComponentRef }),
      ),
    )

    const isComponentExists = await this._isComponentExists(controllerRef)
    if (isComponentExists) {
      await this.boundEditorSDK.components.remove({ componentRef: controllerRef })
    }
  }

  // NOTE:
  // This is not working well with duplicated forms, it can return the wrong form component (depends who is first in the array)
  // Should be fixed with AppWidget
  private async _handleFieldDeletion({
    primaryConnection,
    componentRef,
    connections,
  }: {
    primaryConnection: ComponentConnection
    componentRef: ComponentRef
    connections: ComponentConnection[]
  }) {
    const {
      role,
      controllerRef,
      config: { fieldType },
    } = primaryConnection
    const formComponentRef = await this.findConnectedComponent(controllerRef, ROLE_FORM)

    if (!formComponentRef) {
      return
    }

    const formComponentConnection = await this.getComponentConnection(formComponentRef)
    const {
      config: { plugins },
    } = formComponentConnection

    await this.rules.handleFieldDeletion(
      componentRef,
      primaryConnection,
      formComponentRef,
      formComponentConnection,
    )

    if (role === ROLE_SUBMIT_BUTTON) {
      const {
        config: { preset },
      } = formComponentConnection
      const esi = await this.getEditorSessionId()

      await this._deleteSubmissionButton(formComponentRef, plugins, {
        startBi: {
          form_comp_id: await this.getFormId(formComponentRef, formComponentConnection),
          template: preset,
          esi,
        },
      })
    } else if (role === ROLE_MESSAGE) {
      const {
        config: { successActionType },
      } = formComponentConnection

      if (
        this._shouldOpenTooltipForDeletedSuccessMessage(
          successActionType || SuccessActionTypes.SHOW_MESSAGE,
          formComponentRef,
        )
      ) {
        await this.popNotificationAction({
          componentRef: formComponentRef,
          notificationTrigger: NOTIFICATION_EVENTS.SUCCESS_MESSAGE_DELETED,
          plugins,
        })
      }
    } else if (role === ROLE_DOWNLOAD_MESSAGE) {
      // TODO merge ifs
      const {
        config: { successActionType },
      } = await this.getComponentConnection(formComponentRef)
      if (successActionType === SuccessActionTypes.DOWNLOAD_DOCUMENT && formComponentRef) {
        await this.popNotificationAction({
          componentRef: formComponentRef,
          notificationTrigger: NOTIFICATION_EVENTS.DOWNLOAD_MESSAGE_DELETED,
          plugins,
        })
      }
    } else if (fieldType === FormsFieldPreset.EMAIL) {
      const shouldRestoreNotification = await this.isEmailFieldMissing(controllerRef)

      if (shouldRestoreNotification) {
        await this.popNotificationAction({
          componentRef: formComponentRef,
          notificationTrigger: NOTIFICATION_EVENTS.EMAIL_FIELD_DELETED,
          plugins,
        })
      } //TODO: Extract this configuration without knowledge about specific plugin

      const autofillMemberEmailConnection = _.find(
        connections,
        (field) => field.role === AUTOFILL_MEMBER_EMAIL_ROLE,
      )

      if (!!autofillMemberEmailConnection) {
        await this.fields.handleDeleteEmailFieldWithAutofill({
          formComponentConnection,
          formComponentRef,
          controllerRef,
          autofillMemberEmailConnection,
        })
      }
    } else if (REGISTRATION_FORM_CRUCIAL_FIELD_PRESET_VALUES.includes(fieldType)) {
      await this.popNotificationAction({
        componentRef: formComponentRef,
        notificationTrigger: fieldType,
        plugins,
      })
    } else if (role === ROLE_PREVIOUS_BUTTON) {
      const shouldShowRestoreNotification = await this.steps.isPreviousButtonMissing(
        formComponentRef,
      )

      if (shouldShowRestoreNotification) {
        await this.popNotificationAction({
          componentRef: formComponentRef,
          notificationTrigger: NOTIFICATION_EVENTS.PREVIOUS_BUTTON_DELETED,
          plugins,
        })
      }
    } else if (role === ROLE_NEXT_BUTTON) {
      const shouldShowRestoreNotification = await this.steps.isNextButtonMissing(formComponentRef)

      if (shouldShowRestoreNotification) {
        await this.popNotificationAction({
          componentRef: formComponentRef,
          notificationTrigger: NOTIFICATION_EVENTS.NEXT_BUTTON_DELETED,
          plugins,
        })
      }
    }

    const selectedComponentRefs = await this.boundEditorSDK.selection.getSelectedComponents()
    if (!selectedComponentRefs.length) {
      await this.boundEditorSDK.selection.selectComponentByCompRef({
        compsToSelect: [primaryConnection.controllerRef],
      })
    }
  }

  private async _handleComplexInnerFieldDeletion({
    primaryConnection,
    componentRef,
    connections,
  }: {
    primaryConnection: ComponentConnection
    componentRef: ComponentRef
    connections: ComponentConnection[]
  }) {
    const { role } = primaryConnection
    if (role === COMPLEX_PHONE_ROLES.DROPDOWN) {
      this.biLogger.log({
        evid: EVENTS.PANELS.complexPhoneSettingsPanel.COUNTRY_CODE_DELETED,
      })
    }
  }

  private _shouldOpenTooltipForDeletedSuccessMessage(
    successActionType: SuccessActionTypes,
    componentRef: ComponentRef,
  ) {
    return successActionType === SuccessActionTypes.SHOW_MESSAGE && componentRef
  }

  public async handleDelete(
    componentRef: ComponentRef,
    componentConnections: ComponentConnection[],
  ) {
    const primaryConnection = getPrimaryConnection(componentConnections)
    const { role } = primaryConnection
    if (role === ROLE_FORM) {
      await this._handleFormDeletion({ componentRef, componentConnection: primaryConnection })
    } else {
      await this._saveDeletedFieldForSession({
        componentConnection: primaryConnection,
        componentRef,
      })
      if ([...COMPLEX_FIELDS_INNER_FIELDS].includes(role)) {
        this._handleComplexInnerFieldDeletion({
          primaryConnection,
          componentRef,
          connections: componentConnections,
        })
      } else {
        await this._handleFieldDeletion({
          primaryConnection,
          componentRef,
          connections: componentConnections,
        })
      }
    }
  }

  public async handleDataChanged(
    componentRef: ComponentRef,
    previousData: ComponentStructure['data'],
  ) {
    const componentConnection = await this.getComponentConnection(componentRef)
    await this.rules.handleDataChanged(componentRef, componentConnection, previousData)
  }

  public async addMandatoryFieldByPreset(formRef, fieldPreset) {
    const formComponentConnection = await this.getComponentConnection(formRef)

    // TODO: Move outside this function when doing plugin system
    if (fieldPreset === RegistrationFieldPreset.REGISTRATION_FORM_LINK_TO_LOGIN) {
      return this.fields.restoreLoginLink(formRef)
    }

    const fieldProps = fieldsStore.get(fieldPreset).properties

    const commonStyles = await this.style.getFieldsCommonStylesGlobalDesign(formRef)

    this.fields.addField(formRef, _.get(formComponentConnection, 'config'), {
      commonStyles,
      extraData: {
        ...fieldProps.extraData,
      },
      fieldType: fieldPreset,
    })
  }

  public async addMandatoryEmailField(formRef) {
    const formComponentConnection: ComponentConnection = await this.getComponentConnection(formRef)
    const connectedEmailFields = await this.findConnectedComponentsByCrmType(
      formComponentConnection.controllerRef,
      CRM_TYPES.EMAIL,
    )
    if (connectedEmailFields.length) {
      return
    }

    const email: FieldProperties = fieldsStore.get(FormsFieldPreset.EMAIL).properties
    const commonStyles = await this.style.getFieldsCommonStylesGlobalDesign(formRef)

    this.fields.addField(formRef, _.get(formComponentConnection, 'config'), {
      commonStyles,
      extraData: {
        ...email.extraData,
        props: { required: true },
        connectionConfig: { crmType: CRM_TYPES.EMAIL, crmTag: CRM_TAGS.MAIN },
      },
      fieldType: FormsFieldPreset.EMAIL,
    })
  }

  private async _deleteSubmissionButton(componentRef, plugins, biData) {
    const submitButtonExists = await this.findComponentByRole(componentRef, ROLE_SUBMIT_BUTTON)
    if (submitButtonExists) {
      return
    }
    return this._onSubmissionButtonDelete(componentRef, plugins, biData)
  }

  @withBi({ startEvid: EVENTS.EDITOR.DELETE_SUBMISSION_BUTTON })
  private _onSubmissionButtonDelete(
    componentRef: ComponentRef,
    plugins: ComponentPlugin[],
    _biData,
  ) {
    return this.popNotificationAction({
      componentRef,
      notificationTrigger: NOTIFICATION_EVENTS.SUBMISSION_BUTTON_DELETED,
      plugins,
    })
  }

  private async _reportPaymentNotificationBi(componentRef: ComponentRef) {
    this.biLogger.log({
      evid: EVENTS.EDITOR.PAYMENT_NOTIFICATION_SETUP_CLICK,
      form_comp_id: await this.getFormId(componentRef),
    })
  }

  private _getPopupType = (notificationTrigger: NOTIFICATION_EVENTS | FieldPreset): any => {
    switch (notificationTrigger) {
      case NOTIFICATION_EVENTS.COMPONENT_DRAGGED_INTO_FROM:
        return 'warning'
      default:
        return 'info'
    }
  }

  public async popNotificationAction({
    componentRef,
    plugins,
    notificationTrigger,
  }: {
    componentRef: ComponentRef
    plugins: ComponentPlugin[]
    notificationTrigger: NOTIFICATION_EVENTS | FieldPreset
  }) {
    const isMobileMode = await this.isMobileMode()
    const appStatePlugins = getPluginAppState(convertPluginsToFormsPlugins(plugins)).plugins
    const name = _.camelCase(notificationTrigger)

    // option to filter appStatePlugins With other pluginsOrder by notification trigger name

    const message = isMobileMode
      ? getTranslationByPlugin({
          t: translations.t,
          prefix: 'restoreNotification',
          appStatePlugins,
          postfix: `${name}.mobileText`,
        })
      : getTranslationByPlugin({
          t: translations.t,
          prefix: 'restoreNotification',
          appStatePlugins,
          postfix: `${name}.text`,
        })

    const linkText = isMobileMode
      ? ''
      : getTranslationByPlugin({
          t: translations.t,
          prefix: 'restoreNotification',
          appStatePlugins,
          postfix: `${name}.linkText`,
        })
    const type = this._getPopupType(notificationTrigger)
    this.boundEditorSDK.editor
      .showUserActionNotification({
        title: translations.t(`restoreNotification.title`),
        message,
        type,
        link: {
          caption: linkText,
        },
      })
      .then((shouldRestore) => {
        if (!shouldRestore) {
          return
        }

        switch (notificationTrigger) {
          case NOTIFICATION_EVENTS.COMPONENT_DRAGGED_INTO_FROM:
            this.managePanels.openComponentPanel(componentRef, PanelName.ADD_FIELD)
            break
          case NOTIFICATION_EVENTS.RULES_AFFECTED_FIELD_DELETED:
          case NOTIFICATION_EVENTS.RULES_AFFECTED_OPTION_DELETED:
            this.managePanels.openComponentPanel(
              componentRef,
              PanelName.NEW_FORM_SETTINGS,
              _.noop,
              {
                displayedTab: TABS.RULES,
              },
            )
            break
          case NOTIFICATION_EVENTS.PAYMENT_FORM_ADDED:
            this._reportPaymentNotificationBi(componentRef)
            this.managePanels.openComponentPanel(
              componentRef,
              PanelName.NEW_FORM_SETTINGS,
              _.noop,
              {
                displayedTab: TABS.PAYMENT,
              },
            )
            break
          case NOTIFICATION_EVENTS.SUCCESS_MESSAGE_DELETED:
            this.fields.restoreHiddenMessage(componentRef)
            break
          case NOTIFICATION_EVENTS.DOWNLOAD_MESSAGE_DELETED:
            this.fields.restoreDownloadDocumentMessage(
              componentRef,
              translations.t('settings.successMessage.download'),
            )
            break
          case NOTIFICATION_EVENTS.EMAIL_FIELD_DELETED:
            this.addMandatoryEmailField(componentRef)
            break
          case NOTIFICATION_EVENTS.SUBMISSION_BUTTON_DELETED:
            this.fields.restoreSubmitButton(componentRef)
            break
          case NOTIFICATION_EVENTS.PREVIOUS_BUTTON_DELETED:
            this.steps.restorePreviousButton(componentRef)
            break
          case NOTIFICATION_EVENTS.NEXT_BUTTON_DELETED:
            this.steps.restoreNextButton(componentRef)
            break
          default:
            this.addMandatoryFieldByPreset(componentRef, notificationTrigger)
            break
        }
      })
  }

  public async isCollectionExists(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection,
  ): Promise<boolean> {
    const {
      config: { collectionId },
    } = await this.getComponentConnection(componentRef, componentConnection)
    const validCollectionId = await this.getValidCollectionId(componentRef, collectionId)
    return this.collectionsApi.isCollectionExists(validCollectionId)
  }

  public getLocale() {
    return this.boundEditorSDK.environment.getLocale()
  }

  private async _preparePluginsV2({
    hasCaptchaField,
    componentConnection,
    componentRef,
    itemsListPaymentField,
  }: {
    hasCaptchaField: boolean
    componentConnection: ComponentConnection
    componentRef?: ComponentRef
    itemsListPaymentField?: FormField
  }): Promise<PluginV2[]> {
    const plugins = getPlugins(componentConnection)

    const handlePaymentForm = async (plugin): Promise<PluginV2> => {
      const selectedPaymentOption = _.get(
        componentConnection,
        'config.selectedPaymentOption',
        PAYMENT_OPTIONS.SINGLE,
      )

      const currency: any = await this.getCurrency()

      switch (selectedPaymentOption) {
        case PAYMENT_OPTIONS.CUSTOM:
          return {
            paymentForm: {
              currency,
              itemsV2: _.map(_.get(plugin, 'payload.items'), (item: Product, id) => {
                return {
                  id,
                  name: item.name,
                  quantity: item.quantity || 1,
                  priceRange: {
                    minPrice: _.isEmpty(_.trim(item.min)) ? '0' : _.trim(item.min),
                    maxPrice: _.trim(item.max),
                  },
                }
              }),
            },
          }
        case PAYMENT_OPTIONS.LIST:
          const paymentItemsMapping = itemsListPaymentField?.paymentItemsMapping
          return {
            paymentForm: {
              currency,
              itemsV2: _.compact(
                _.map(_.get(itemsListPaymentField, 'options'), (option: RadioOption) => {
                  const item = _.get(paymentItemsMapping, `${option.value}`)
                  const price = _.get(item, 'price')
                  const name = _.get(item, 'label')

                  return price !== undefined
                    ? {
                        id: option.value,
                        name,
                        quantity: 1,
                        fixedPrice: {
                          price,
                        },
                      }
                    : null
                }),
              ),
            },
          }
        default:
          return {
            paymentForm: {
              currency,
              items: _.values(_.get(plugin, 'payload.items')),
            },
          }
      }
    }
    const pluginsV2: PluginV2[] = await Promise.all(
      plugins.map(async (plugin: ComponentPlugin) => {
        switch (plugin.id) {
          case FormPlugin.FORM_BUILDER:
            return { [_.camelCase(plugin.id)]: {} }
          case FormPlugin.GET_SUBSCRIBERS:
            const doubleOptIn = _.get(componentConnection, 'config.doubleOptIn')
            return { [_.camelCase(plugin.id)]: doubleOptIn ? { mode: 'DOUBLE_OPT' } : {} }
          case FormPlugin.REGISTRATION_FORM:
            return { [_.camelCase(plugin.id)]: {} }
          case FormPlugin.PAYMENT_FORM:
            return await handlePaymentForm(plugin)
          case FormPlugin.MULTI_STEP_FORM:
            const stepComponents = await this.boundEditorSDK.components.getChildren({
              componentRef,
            })
            return {
              multiStepForm: {
                steps: stepComponents.length,
              },
            }
          case FormPlugin.LIMIT_FORM_SUBMISSONS:
            return convertToV2SubmissionLimitStructure(plugin)
          default:
            return null
        }
      }),
    )

    if (hasCaptchaField) {
      const captchaPlugin: PluginV2 = { captcha: {} }
      pluginsV2.push(captchaPlugin)
    }

    const inboxOptOut = _.get(componentConnection, 'config.inboxOptOut')

    if (inboxOptOut) {
      const inboxOptOutPlugin = { inboxOptOut: {} }
      pluginsV2.push(inboxOptOutPlugin)
    }

    return _.compact(pluginsV2)
  }

  public async getValidCollectionId(
    componentRef: ComponentRef,
    collectionId: string,
  ): Promise<string | null> {
    const formId = await this.getFormId(componentRef)
    return getValidCollectionId(formId, collectionId)
  }

  public getSchema(collectionId: string) {
    return this.collectionsApi.getSchema(collectionId)
  }

  public async getFormId(formRef: ComponentRef, connection?: ComponentConnection): Promise<string> {
    const payload = await this.getComponentConnection(formRef, connection)
    const config = _.get(payload, 'config')
    const controllerRef = _.get(payload, 'controllerRef')

    if (!config || !controllerRef) {
      this.ravenInstance.captureMessage('formRef component connection missing config', {
        stacktrace: true,
      })
      return _.get(formRef, 'id', 'missing-id')
    }

    const { useControllerId } = config
    const masterRef = useControllerId ? controllerRef : formRef
    const templateRef = await this._getMasterComponent(masterRef)

    return templateRef.id
  }

  private async _getMasterComponent(componentRef: ComponentRef): Promise<ComponentRef> {
    const templateRef = await this.boundEditorSDK.components.refComponents.getTemplateComponent({
      componentRef,
    })
    return templateRef || componentRef
  }

  private _getFieldDefinition(
    field: FormField,
    isEditorX: boolean,
    additionalFieldsInfo: { hasCaptchaField: boolean; itemsListPaymentField: FormField },
  ) {
    let fieldId = field.componentRef.id

    if (isEditorX) {
      // Temp solution (until code will move to server) to construct id by original compId & not inflated Id if exists on masterPage (refCompId_r_compId -> compId)
      if (_.includes(fieldId, '_r_')) {
        fieldId = _.split(fieldId, '_r_')[1]
      }
    }

    const fieldDef: FormFieldDefinition = {
      fieldId,
      fieldName: field.crmLabel,
      fieldCollectionType: field.collectionFieldType,
    }

    if (field.fieldType === FormsFieldPreset.GENERAL_RECAPTCHA) {
      additionalFieldsInfo.hasCaptchaField = true
    }

    if (field.fieldType === FormsFieldPreset.GENERAL_ITEMS_LIST) {
      additionalFieldsInfo.itemsListPaymentField = field
    }

    if (allowCollectionSync(field.fieldType)) {
      if (field.collectionFieldKey) {
        fieldDef.fieldCollectionKey = field.collectionFieldKey
      }

      const fieldProperties = fieldsStore.get(field.fieldType).properties

      if (!fieldDef.fieldCollectionType && fieldProperties.collectionFieldType) {
        fieldDef.fieldCollectionType = fieldProperties.collectionFieldType
      }
    }

    return fieldDef
  }

  public async sendAllFormsData() {
    const collectionsById = await this.collectionsApi.getCollectionMapById()
    const allFormRefs = await this.getAllFormsRefs()

    let isEditorX = false

    try {
      isEditorX = _.get(global, 'commonConfig.brand') === 'editorx'
    } catch (err) {}

    const forms = await Promise.all(
      allFormRefs.map(async (formRef) => {
        if (!formRef) {
          return
        }

        const componentConnection = await this.getComponentConnection(formRef)
        const { config } = componentConnection
        const validCollectionId = await this.getValidCollectionId(formRef, config.collectionId)
        const fields = await this.fields.getFieldsSortByXY(formRef)
        const additionalFieldsInfo: {
          hasCaptchaField: boolean
          itemsListPaymentField: FormField
        } = {
          hasCaptchaField: false,
          itemsListPaymentField: null,
        }

        // TODO: Refactor this without going other the fields array more than once

        const formFields = _.map(fields, (field) => {
          let fieldId = field.componentRef.id

          if (isEditorX) {
            // Temp solution (until code will move to server) to construct id by original compId & not inflated Id if exists on masterPage (refCompId_r_compId -> compId)
            if (_.includes(fieldId, '_r_')) {
              fieldId = _.split(fieldId, '_r_')[1]
            }
          }

          if (field.fieldType === FormsFieldPreset.COMPLEX_ADDRESS_WIDGET) {
            return field.childFields.map((childField) => {
              return this._getFieldDefinition(childField, isEditorX, additionalFieldsInfo)
            })
          }

          return this._getFieldDefinition(field, isEditorX, additionalFieldsInfo)
        }).flat()

        const { hasCaptchaField, itemsListPaymentField } = additionalFieldsInfo
        const pluginsV2Dto = await this._preparePluginsV2({
          hasCaptchaField,
          componentConnection,
          componentRef: formRef,
          itemsListPaymentField,
        })

        const formId = await this.getFormId(formRef, componentConnection)

        return {
          formId,
          formName: (await this.shouldFetchRemoteName()) ? null : config.formName,
          collectionId: collectionsById[validCollectionId] && validCollectionId,
          pluginsV2: pluginsV2Dto,
          fields: formFields,
        }
      }),
    )

    const formData: PublishSiteRequest = {
      forms: _.filter(forms, (x) => !!x),
      ignoreFormName: await this.shouldFetchRemoteName(),
    }

    if (isEditorX) {
      // Temp solution until moved to server: in some cases we get more than one form that is part of master section in EditorX
      formData.forms = _.uniqBy(formData.forms, (f) => f.formId)
    }

    return this.remoteApi.publishSite(formData)
  }

  public isRegistrationForm(componentRef: ComponentRef): Promise<boolean> {
    return this._isPluginExists(componentRef, { id: FormPlugin.REGISTRATION_FORM })
  }

  public isMultiStepForm(componentRef: ComponentRef): Promise<boolean> {
    return this._isPluginExists(componentRef, { id: FormPlugin.MULTI_STEP_FORM })
  }

  public isGetSubscribers(componentRef: ComponentRef): Promise<boolean> {
    return this._isPluginExists(componentRef, { id: FormPlugin.GET_SUBSCRIBERS })
  }

  private async _isPluginExists(
    componentRef: ComponentRef,
    plugin: ComponentPlugin,
  ): Promise<boolean> {
    const formCompRef: ComponentRef = await this.findComponentByRole(componentRef, ROLE_FORM)
    const { config } = await this.getComponentConnection(formCompRef)
    const plugins: ComponentPlugin[] = _.get(config, 'plugins', [])
    return !!_.find(plugins, plugin)
  }

  public hasConnectedPayment(): Promise<boolean> {
    return this.remoteApi
      .getConnectedPayments()
      .then((connectedPayments) => connectedPayments.paymentMethods.length > 0)
  }

  public getCurrency() {
    return this.boundEditorSDK.info.getCurrency()
  }

  // TODO: Merge with other config fetch
  // TODO: One possible solution, replace with getPlugins(componentConnection)
  public async getFormConfigData(componentRef) {
    const formCompRef: ComponentRef = await this.findComponentByRole(componentRef, ROLE_FORM)
    const { config } = await this.getComponentConnection(formCompRef)
    const plugins: FormPlugin[] = _.map(_.get(config, 'plugins', []), 'id')
    const preset = _.get(config, 'preset')

    return { plugins, preset }
  }

  public async shouldFetchRemoteName() {
    return (
      this.experiments.enabled('specs.crm.FormsEditorEditFormNameRemote') &&
      (await this.getUserId()) !== '6316f5bb-5858-4345-92ed-04566c1d7f54' // autopilot user guid
    )
  }

  public async reportBiFirstSave() {
    const controllers: {
      controllerRef: ComponentRef
    }[] = await this.boundEditorSDK.controllers.listAllControllers()
    return Promise.all(
      _.map(controllers, async ({ controllerRef }) => {
        const formRef = await this.findConnectedComponent(controllerRef, ROLE_FORM)
        const connection = await this.getComponentConnection(formRef)

        this.biLogger.log({
          evid: EVENTS.EDITOR.USER_SAVE_TEMPLATE_WITH_FORM,
          form_comp_id: await this.getFormId(formRef, connection),
          template: _.get(connection, 'config.preset', 'unknown'),
          templateId: this.siteId,
        })
      }),
    )
  }

  public async reportBiAppWidgetPasted(formRef: ComponentRef): Promise<void> {
    const connection = await this.getComponentConnection(formRef)
    const {
      config: { preset, isDummyForm },
    } = connection
    this.biLogger.log({
      evid: EVENTS.EDITOR.PASTE_APP_WIDGET,
      source_name: 'copy form',
      form_comp_id: await this.getFormId(formRef, connection),
      template: isDummyForm ? 'loader' : preset,
    })
  }

  public selectComponent(componentRef: ComponentRef): Promise<null> {
    return this.boundEditorSDK.selection.selectComponentByCompRef({
      compsToSelect: [componentRef],
    })
  }

  private async _isAppInstalled(appDefinitionId: string): Promise<boolean> {
    try {
      const isInstalled = await this.boundEditorSDK.document.tpa.isApplicationInstalled({
        appDefinitionId,
      })
      return isInstalled
    } catch (ex) {
      return false
    }
  }

  public isWixChatInstalled(): Promise<boolean> {
    return this._isAppInstalled(WIX_APP_ID.CHAT)
  }

  public isMembersAreaInstalled(): Promise<boolean> {
    return this._isAppInstalled(WIX_APP_ID.MEMBERS_AREA)
  }

  public isBlogInstalled(): Promise<boolean> {
    return this._isAppInstalled(WIX_APP_ID.BLOG)
  }

  public isForumInstalled(): Promise<boolean> {
    return this._isAppInstalled(WIX_APP_ID.FORUM)
  }

  public isEventsInstalled(): Promise<boolean> {
    return this._isAppInstalled(WIX_APP_ID.EVENTS)
  }

  public isGroupsInstalled(): Promise<boolean> {
    return this._isAppInstalled(WIX_APP_ID.GROUPS)
  }

  public isSharedGalleryInstalled(): Promise<boolean> {
    return this._isAppInstalled(WIX_APP_ID.SHARED_GALLERY)
  }

  public isFileShareInstalled(): Promise<boolean> {
    return this._isAppInstalled(WIX_APP_ID.FILE_SHARE)
  }

  public async createTag(tagName: string) {
    return this.remoteApi.createTag(tagName) // TODO move to add-form only
  }

  public getLabels() {
    return this.remoteApi.getLabels() // TODO move to add-form only
  }

  public async addHeightToContainers(componentRef: ComponentRef, extraHeight: number) {
    const ancestors = await this.boundEditorSDK.components.getAncestors({ componentRef })
    const allAncestors = [componentRef, ...ancestors]
    const orderedAncestors: ComponentRef[] =
      extraHeight > 0 ? _.reverse(allAncestors) : allAncestors

    const heights = _.keyBy(
      await Promise.all(
        orderedAncestors.map(async (componentRef) => ({
          componentRef,
          layout: await this.boundEditorSDK.components.layout.get({ componentRef }),
        })),
      ),
      'componentRef.id',
    )

    const addHeight = async (compRef: ComponentRef) => {
      if (!heights[compRef.id].layout.height) {
        return Promise.resolve()
      }

      return this.boundEditorSDK.components.layout.update({
        componentRef: compRef,
        layout: { height: heights[compRef.id].layout.height + extraHeight },
      })
    }

    return orderedAncestors.reduce(
      (previousPromise, nextAncestor) => previousPromise.then(() => addHeight(nextAncestor)),
      Promise.resolve(),
    )
  }

  public async logFetchThemesFailed(compRef: ComponentRef | null, reason: string) {
    const esi = await this.getEditorSessionId()
    this.biLogger.log({
      evid: EVENTS.EDITOR.FETCH_FAILED,
      esi,
      name: 'global design',
      reason,
      form_comp_id: _.get(compRef, 'id'),
    })
  }

  public async logFetchPresetsFailed(compRef: ComponentRef | null, reason: string) {
    const esi = await this.getEditorSessionId()
    this.biLogger.log({
      evid: EVENTS.EDITOR.FETCH_FAILED,
      esi,
      name: 'preset',
      reason,
      form_comp_id: _.get(compRef, 'id'),
    })
  }

  public isExperimentEnabled(experiment) {
    return this.experiments.enabled(experiment)
  }

  public isEditorExperimentEnabled(experiment) {
    return this.isExperimentEnabled(experiment) || this.experiments.get(experiment) === 'new'
  }

  public async convertFormToAppWidget(componentRef: ComponentRef) {
    const ancestors = await this.boundEditorSDK.components.getAncestors({
      componentRef,
    })
    const containerRef = _.first(ancestors) || (await this.boundEditorSDK.pages.getCurrent())
    const { config: connectionConfig } = await this.getComponentConnection(componentRef)
    const { layout, style, components } = (
      await this.boundEditorSDK.components.get({
        componentRefs: [componentRef],
        properties: ['layout', 'style', 'components'],
      })
    )[0]

    await this.removeComponentRef(componentRef)

    const changeComponentConnections = (components) =>
      components.reduce((acc, component) => {
        if (component.components) {
          component.components = changeComponentConnections(component.components)
        }

        if (component.connections) {
          const primaryConnection = _.find(component.connections.items, (comp) => comp.isPrimary)
          primaryConnection.controllerId = 'data_item_id_placeholder'
        }
        acc = acc.concat([component])
        return acc
      }, [])

    const containerDefinition = {
      data: {
        layout,
        style,
        type: 'Container',
        componentType: COMPONENT_TYPES.FORM_CONTAINER,
        skin: 'wysiwyg.viewer.skins.FormContainerSkin',
        components: changeComponentConnections(components),
      },
      connectionConfig,
    }

    const controllerRef = await this.addForm.addAppWidget(containerRef, containerDefinition)
    const formRef = await this.getFormContainerOfAppWidget(controllerRef)
    await this.addForm.updateFormCollectionIdADI(formRef, connectionConfig.collectionId)

    await this.appState.setState([controllerRef])
  }

  public async makeActionOnMobileAndDesktop(
    desktopRef: ComponentRef,
    action: (compRef: ComponentRef) => Promise<any>,
  ): Promise<any[]> {
    const mobileRef: ComponentRef = { id: desktopRef.id, type: 'MOBILE' }
    if (await this._isComponentExists(mobileRef)) {
      return Promise.all([action(mobileRef), action(desktopRef)])
    }
    return action(desktopRef)
  }

  public panelGoBack() {
    return Promise.resolve()
  }

  public panelUpgrade() {
    return Promise.resolve()
  }

  public panelDelete() {
    return Promise.resolve()
  }

  public async getAllFormsRefs({ shouldExcludeSignupForm = false } = {}): Promise<ComponentRef[]> {
    const allControllers: ControllerRef[] = await this.boundEditorSDK.controllers.listAllControllers()
    const allFormRefs: ComponentRef[] = await Promise.all(
      allControllers.map(async ({ controllerRef }) => {
        const connectedRef = this.findConnectedComponent(controllerRef, ROLE_FORM)

        if (shouldExcludeSignupForm && (await this.isRegistrationForm(await connectedRef)))
          return null
        return connectedRef
      }),
    )
    return allFormRefs.filter((formRef) => !!formRef)
  }

  public async getAllFormsRefsAndConfigs(): Promise<ComponentRefAndConfig[]> {
    const allFormsRefs = await this.getAllFormsRefs()
    return Promise.all(
      allFormsRefs.map(async (componentRef) => {
        const { config } = await this.getComponentConnection(componentRef)
        return {
          componentRef,
          componentConfig: config,
        }
      }),
    )
  }

  public async createMissingFormLabels(allForms: ComponentRefAndConfig[], isTemplate: boolean) {
    const formsWithMissingLabel = allForms.filter(
      (form) =>
        form.componentConfig &&
        form.componentConfig.formName &&
        (isTemplate || !form.componentConfig.formLabelId),
    )

    if (formsWithMissingLabel.length) {
      try {
        await Promise.all(
          formsWithMissingLabel.map(async (form) =>
            this.addForm.createNewFormName(form.componentRef, form.componentConfig),
          ),
        )
      } catch {
        return Promise.resolve()
      }
      await this.saveSite()
    }
  }

  private _saveDeletedFieldForSession({
    componentConnection,
    componentRef,
  }: {
    componentConnection: ComponentConnection
    componentRef: ComponentRef
  }) {
    const {
      config: { crmLabel },
    } = componentConnection

    this.apiSessionStorage.set('deletedFields', {
      ...this.apiSessionStorage.get('deletedFields'),
      [componentRef.id]: { crmLabel },
    })
  }

  public getSessionDeletedFields() {
    return this.apiSessionStorage.get('deletedFields') || {}
  }

  public async migrateBadEmails() {
    const badEmail = 'arielwollek@gmail.com'
    const forms = await this.getAllFormsRefs()
    await Promise.all(
      forms.map(async (form) => {
        const connection = await this.getComponentConnection(form)
        const config = _.get(connection, 'config')
        const emailsIds = _.get(config, 'emailIds')
        if (!emailsIds) {
          return
        }
        const emails = await this.settings.getEmails(emailsIds)
        const badEmailsIndex = emails.findIndex((email) => email.email === badEmail)
        if (badEmailsIndex !== -1) {
          const newEmailIds = emails
            .map((email, index) =>
              index === badEmailsIndex ? (index === 0 ? '' : null) : email.emailId,
            )
            .filter((email) => _.isString(email))
          await this.setComponentConnection(form, { emailIds: newEmailIds }, false)
        }
      }),
    )
  }

  public async getUserGEO(): Promise<string | undefined> {
    try {
      const userGEO = await (<any>this.boundEditorSDK.document.info).getUserGEO()
      return GEO_CODE_MAP[userGEO]
    } catch (err) {}
  }

  public async getFormContainerFromComplexFieldController(
    controllerRef: ComponentRef,
  ): Promise<ComponentRef> {
    const formsControllerRef = (await this.getComponentConnection(controllerRef)).controllerRef
    return this.findConnectedComponent(formsControllerRef, ROLE_FORM)
  }

  public async createCollections() {
    const forms = await this.getAllFormsRefsAndConfigs()
    const formsWithoutCollections = forms
      .filter(
        ({ componentConfig, componentRef }) =>
          !getValidCollectionId(componentRef.id, componentConfig.collectionId) &&
          !componentConfig.isDummyForm,
      )
      .map((form) => form.componentRef)

    const formBuilderLog: string = 'form builder log:'
    if (!formsWithoutCollections.length) {
      console.log(`${formBuilderLog} no forms found!`)
      return
    }

    let createCollectionsSuccess: boolean = true
    let error = ''
    console.log(
      `${formBuilderLog} trying to create collections for ${formsWithoutCollections.length} forms`,
    )
    await formsWithoutCollections.reduce(
      (createCollectionPromise, formRef: ComponentRef) =>
        createCollectionPromise
          .then(() => this._createCollection(formRef, {}, false))
          .catch((_error) => {
            createCollectionsSuccess = false
            error = JSON.stringify(_error)
            console.error(`${formBuilderLog} form ${formRef.id} failed: ${error}`)
          }),
      Promise.resolve(),
    )

    if (!createCollectionsSuccess) {
      console.error(`${formBuilderLog} forms create collections fail`)
      throw new Error(`${formBuilderLog} forms create collections fail ${error}`)
    }

    // if (createCollectionsSuccess) {
    //   await this.saveSite()
    //   console.log(
    //     `${formBuilderLog} forms create collections success for ${formsWithoutCollections.length} forms`,
    //   )
    // } else {
    //   console.error(`${formBuilderLog} forms create collections fail`)
    //   throw new Error(`${formBuilderLog} forms create collections fail`)
    // }
  }
}
