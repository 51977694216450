import { SyncData } from './types'
import { CUSTOM_FIELD } from '../../../constants/crm-types-tags'

export const NEW_FIELD_ID = 'NEW_FIELD_ID'

export const unsyncedSyncData: SyncData = {
  crmType: CUSTOM_FIELD,
  crmTag: undefined,
  customFieldId: undefined,
  customFieldName: undefined,
}

export const LEARN_MORE_HELP_ARTICLE_ID = '95010e33-51c4-4282-9724-9832fde0e445'
