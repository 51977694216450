import 'regenerator-runtime/runtime'
import { fetcher } from '../../utils/utils'
import CoreApi from '../core/core-api'

class EditorAppMetaData {
  private coreApiResolver: DataResolver<CoreApi>
  public isInitialized: boolean
  public appManifest: any

  constructor() {
    this.isInitialized = false
    this.coreApiResolver = fetcher<CoreApi>()
  }

  getCoreApi(): Promise<CoreApi> {
    return this.coreApiResolver.getData
  }

  setCoreApi(coreApi: CoreApi): void {
    this.coreApiResolver.resolveData(coreApi)
  }
}

export const editorAppMetaData = new EditorAppMetaData()
