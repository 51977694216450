export const FORM_PADDING = 60
export const ADI_FORM_PADDING = 20
export const PADDING_FROM_TITLE = 15
export const DEFAULT_FIELD_MARGIN = 30
export const TOP_PADDING = 35
export const LAST_FIELD_PADDING = 10
export const HEIGHT_PADDING_WITH_TITLES = 15
export const HEIGHT_PADDING_WITHOUT_TITLES = 10
export const MULTI_STEP_BUTTON_SIDE_MARGIN = 30

export type FieldsLayout = {
  lastFieldPadding: number
  heightPadding: number
  startX: number
  formPadding: number
  defaultWidth: Function
  rolePadding: {
    SUBMIT: number
    MESSAGE: number
  }
}

export const getFieldsLayout = (isAdiLayout, showTitles, rowSpacing, colSpacing) => {
  const fieldsLayout: FieldsLayout = {
    lastFieldPadding: LAST_FIELD_PADDING,
    heightPadding: (!isAdiLayout ? 0 : showTitles ? HEIGHT_PADDING_WITH_TITLES : HEIGHT_PADDING_WITHOUT_TITLES) + rowSpacing,
    startX: isAdiLayout ? 0 : FORM_PADDING,
    formPadding: isAdiLayout ? ADI_FORM_PADDING : FORM_PADDING,
    defaultWidth: (width, columns) => {
      const fieldMargin = colSpacing !== null ? colSpacing : DEFAULT_FIELD_MARGIN
      return isAdiLayout
        ? width / columns
        : (width - FORM_PADDING * 2 - (columns - 1) * fieldMargin) / columns
    },
    rolePadding: !isAdiLayout
      ? {
          SUBMIT: 30,
          MESSAGE: 30,
        }
      : {
          SUBMIT: 30,
          MESSAGE: 10,
        },
  }
  return fieldsLayout
}
